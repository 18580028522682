import ImageWithFallback from "../../shared/ImageWithFallback";
import styled from "styled-components";
import { LightMemberDetailsFragment } from "../../../gql/types";

const Verified = styled(ImageWithFallback)<{ size: number }>`
  width: ${({ size }) => size + "px"};
  height: ${({ size }) => size + "px"};
`;

type Props = {
  size?: number;
  user: LightMemberDetailsFragment;
};

export default function VerifiedBadge({ user, size = 14 }: Props) {
  if (!user || typeof user === "undefined" || (!user.verifiedImageUrl && !user.isVerified)) {
    return null;
  }

  return (
    <Verified
      size={size}
      width={size}
      height={size}
      src={user.verifiedImage}
      fallbackSrc="/icons/verified.png"
      alt=""
    />
  );
}
