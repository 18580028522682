import styled from "styled-components";
import { MEDIA_QUERY_SM } from "../../utilities/constants/mediaQueries";

type SectionHeadingProps = {
  title: string;
  divider?: boolean;
  main?: boolean;
  gap?: string;
  fontSize?: string;
  dividerHeight?: string;
};

const Container = styled.div<{ main?: boolean; gap: string }>`
  display: flex;
  gap: ${({ gap }) => gap};
  align-items: baseline;
  width: 100%;
  ${({ main }) => !main && "padding-top: 1rem;"}
`;

const StyledHeading = styled.h2<{ fontSize?: string }>`
  color: var(--color-gray-1);
  font-weight: 600;
  line-height: 1.4;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1.5rem")};
  font-family: var(--font-family-headline);
  margin: 0;
  white-space: nowrap;

  @media (min-width: ${MEDIA_QUERY_SM}) {
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "2rem")};
  }
`;

const Divider = styled.div<{ dividerHeight: string }>`
  background: var(--color-gray-1);
  height: ${({ dividerHeight }) => dividerHeight};
  width: 100%;
`;

export default function SectionHeading({
  title,
  divider,
  gap = "2rem",
  fontSize = undefined,
  dividerHeight = "0.25rem",
}: SectionHeadingProps) {
  return (
    <Container gap={gap}>
      <StyledHeading fontSize={fontSize}>{title}</StyledHeading>
      {divider && <Divider dividerHeight={dividerHeight} />}
    </Container>
  );
}
