import styled from "styled-components";
import ImageWithFallback from "./ImageWithFallback";
import Subtext from "./Subtext";
import Text from "./Text";
import getUrl from "../../utilities/getUrl";

const Container = styled.div`
  background: var(--color-gray-6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-top: 0.5rem;
  max-width: 20rem;
  min-width: content-fit;
  border-radius: 0.75rem;

  &:hover {
    transform: scale(1.04);
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  background: white;
  border-radius: 0.75rem;
  padding: 0.25rem;
`;

export default function TwitterPlaceholder({ url, loading }: { url: string; loading: boolean }) {
  let username = url
    .replace("https://twitter.com/", "@")
    .replace("http://twitter.com/", "@")
    .replace("twitter.com/", "@");

  return (
    <a href={getUrl(url, loading)} target="_blank" rel="noreferrer">
      <Container>
        <TextWrapper>
          <Text>{`Twitter - ${username ?? "External Twitter Link"}`}</Text>
          <Subtext>{getUrl(url, loading)}</Subtext>
        </TextWrapper>
        <ImageWrapper>
          <ImageWithFallback src={"/icons/twitter.png"} alt="Twitter logo" width={40} height={40} />
        </ImageWrapper>
      </Container>
    </a>
  );
}
