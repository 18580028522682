import styled from "styled-components";
import { MerlotColorAlias } from "../../../utilities/constants/colors";
import { useMerlotColorAlias } from "../../../utilities/hooks/useMerlotColorAlias";
import { PillDetailsFragment } from "../../../gql/types";

type Props = {
  tagItem?: PillDetailsFragment;
};

const GutterBarBorder = styled.div<{ gutterColor?: string }>`
  background: ${(props) => props.gutterColor};
  border-color: ${(props) => props.gutterColor};
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  display: flex;
  width: 4px;
`;

export default function GutterBar({ tagItem }: Props) {
  const color = useMerlotColorAlias(
    (tagItem?.gutterColor ?? "Background") as MerlotColorAlias | undefined,
    tagItem?.gutterColorAlpha ?? 1,
  );
  return <GutterBarBorder gutterColor={color} />;
}
