import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { LINK_DATA_DETAILS } from "../fragments";
import { FetchLinkDataQuery, FetchLinkDataQueryVariables } from "../types";

export const FETCH_LINK_DATA: TypedDocumentNode<FetchLinkDataQuery, FetchLinkDataQueryVariables> = gql`
  ${LINK_DATA_DETAILS}
  query fetchLinkData($url: String!) {
    fetchLinkData(url: $url) {
      ...LinkDataDetails
    }
  }
`;
