import styled from "styled-components";
import { useRouter } from "next/router";
import { PROFILE, useRightSidebarContext } from "../../state/RightSidebar";
import { BadgeFragmentFragment, LightMemberDetailsFragment } from "../../../gql/types";
import Image from "next/image";
// import { useTheme } from "../../../utilities/hooks/useTheme";
import Text from "../../shared/Text";
import { useFeatureFlagsContext } from "../../state/FeatureFlagProvider";
import VerifiedBadge from "../../shared/user/VerifiedBadge";

const UsernameButton = styled.div<{ isReadOnly: boolean }>`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  ${({ isReadOnly }) => isReadOnly && `cursor: default`};
`;

const Heading = styled.h4<{ fontSize: number }>`
  margin: 0;
  font-weight: 600;
  font-size: ${({ fontSize }) => fontSize + "rem"};
  line-height: 1;
`;

const Badges = styled.div`
  display: flex;
  align-items: center;
  gap: 0.125rem;
`;

const Badge = styled.button`
  display: flex;
  align-items: center;
  gap: 0.125rem;
`;

const RiftText = styled(Text)`
  font-family: var(--font-family-headline);
  font-size: 0.95rem;
  font-weight: 600;
`;

function Username({
  isReadOnly = false,
  isExternal = false,
  prefix = "",
  fontSize = 1,
  user,
  showBadges = true,
  onBadgeTap = undefined,
  className,
}: {
  user: LightMemberDetailsFragment;
  isExternal?: boolean;
  isReadOnly?: boolean;
  fontSize?: number;
  prefix?: string;
  showBadges?: boolean;
  onBadgeTap?: (badge: BadgeFragmentFragment) => void;
  className?: string;
}) {
  const { featureFlags } = useFeatureFlagsContext();
  const { displayPanel } = useRightSidebarContext();
  const router = useRouter();
  // @TODO darkmode ?
  // const isDarkMode = useTheme();

  if (!user) return null;

  return (
    <UsernameButton
      isReadOnly={isReadOnly}
      onClick={(e) => {
        if (isReadOnly) return;
        e.stopPropagation();

        isExternal ? router.push(`/${user.username}`) : displayPanel(PROFILE, user.userId);
      }}
    >
      <Heading fontSize={fontSize ?? 1} className={className}>
        {prefix ? `${prefix} ` : ""}
        {user && user.username ? `${user.username}` : "Bunches"}
      </Heading>
      {showBadges &&
        (featureFlags?.["decoration_badges"] ? (
          <Badges>
            {user.decorationBadges?.map((b, i) => (
              <Badge
                key={`user-badge-${i}`}
                onClick={(e) => {
                  e.stopPropagation();
                  !!onBadgeTap && onBadgeTap(b);
                }}
              >
                {/* <Image src={isDarkMode ? b.badge.darkModeUrl : b.badge.lightModeUrl} height={20} width={20} alt="" /> */}
                <Image src={b.badge.lightModeUrl} height={14} width={14} alt="" />
                <RiftText>{b.text ? b.text : null}</RiftText>
              </Badge>
            ))}
          </Badges>
        ) : (
          user.verifiedImage && <VerifiedBadge size={fontSize * 14} user={user} />
        ))}
    </UsernameButton>
  );
}

export default Username;
