import { useAuthContext } from "../../../state/AuthProvider";
import styled from "styled-components";
import { useBunchesContext } from "../../../state/BunchesProvider";
import { routeToBunch } from "../../../../utilities/bunch/useRouteSlug";
import { useAnalyticsContext } from "../../../state/AnalyticsProvider";
import { BUNCH, useRightSidebarContext } from "../../../state/RightSidebar";

const StyledText = styled.span`
  color: var(--color-primary);
`;

export default function BunchMessageComponent({
  component,
  index,
}: {
  component: { id?: string; content: string };
  index: number;
}) {
  const { currentUser } = useAuthContext();
  const { displayPanel } = useRightSidebarContext();
  const { checkIfMember, bunches } = useBunchesContext();
  const { pushTrackEvent } = useAnalyticsContext();

  return (
    <button
      key={index}
      onClick={async () => {
        if (!currentUser || !bunches || !component.id) return;
        const isMember = await checkIfMember(component.id, currentUser.userId);

        const relatedBunch = bunches.find((b) => b.bunchId === component.id);
        !isMember ? displayPanel(BUNCH, component.id) : relatedBunch && routeToBunch(relatedBunch);
        pushTrackEvent("Tapped Bunch from Messsage", { bunchId: component.id, name: component.content });
      }}
    >
      <StyledText>{component.content}</StyledText>
    </button>
  );
}
