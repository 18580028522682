import Link from "next/link";
import { useQuery } from "@apollo/client";
import { FETCH_LINK_DATA } from "../../gql/message/fetchLinkData";
import TwitterPlaceholder from "./TwitterPlaceholder";
import { TwitterTweetEmbed } from "react-twitter-embed";
import ImageWithFallback from "./ImageWithFallback";
import Subtext from "./Subtext";
import Text from "./Text";
import styled from "styled-components";
import getUrl from "../../utilities/getUrl";
import Image from "next/image";
import { MEDIA_QUERY_MD } from "../../utilities/constants/mediaQueries";
import { getYoutubeVideoId } from "../../utilities";
import { useCallback, useEffect, useState } from "react";
import FrameComponent from "../chat/message/FrameComponent";
import { FrameProperties } from "../../pages/api/frame";

const Container = styled.div`
  display: flex;
  background: var(--color-gray-6);
  border-radius: 0.75rem;
  margin-top: 0.5rem;
  transition: transform var(--animation-timing-quick) var(--timing-easeOutQuart);
  padding: 1rem;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    width: 384px;
  }
  &:hover {
    transform: scale(1.04);
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  gap: 0.5rem;
`;

const SpotifyWrapper = styled.div`
  margin-top: 0.5rem;
  height: 15rem;

  iframe {
    border: none;
  }

  @media (min-width: ${MEDIA_QUERY_MD}) {
    width: 24rem;
    height: 22rem;
  }
`;

const YoutubeWrapper = styled.div`
  margin-top: 0.5rem;
  border-radius: 0.75rem;
  overflow: hidden;
  aspect-ratio: 640/360;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    max-width: 640px;
  }
`;

const StyledSpan = styled.span`
  color: var(--color-primary);
`;

export default function LinkPreview({ url }: { url: string }) {
  const [frameLoading, setFrameLoading] = useState(true);
  const [frameData, setFrameData] = useState<FrameProperties>();

  const { loading, data } = useQuery(FETCH_LINK_DATA, {
    variables: { url: url.toLowerCase() },
  });

  const fetchFrameData = useCallback(async () => {
    if (!url) return;
    try {
      const response = await fetch(`/api/frame?url=${url}`);

      const data = await response.json();

      const frame = !!data.frame ? JSON.parse(data.frame) : undefined;

      setFrameData(frame);
      setFrameLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, [url]);

  useEffect(() => {
    fetchFrameData();
  }, [fetchFrameData]);

  if (loading || frameLoading) {
    return <div></div>;
  }

  // @TODO Right now only renders frames with "link" buttons. Change when Open Frame spec is released
  if (!!frameData?.version && frameData?.buttons?.some((b) => b.action === "link"))
    return <FrameComponent frame={frameData} />;

  // Twitter Embed
  if (url.toLowerCase().includes("twitter.com")) {
    const twitterRegex = /\/status\/(\d+)/g;
    const twitterMatch = [...url.matchAll(twitterRegex)];
    const twitterId = twitterMatch && twitterMatch[0] ? twitterMatch[0][1] : false;
    if (twitterId) {
      return <TwitterTweetEmbed tweetId={`${twitterId}`} />;
    } else {
      return <TwitterPlaceholder url={url} loading={loading} />;
    }
  }

  // Spotify Embed
  if (url.toLowerCase().includes("spotify")) {
    const link = new URL(url);
    return (
      <SpotifyWrapper>
        <iframe
          title="Spotify Web Player"
          src={`https://open.spotify.com/embed${link.pathname}`}
          width={"100%"}
          height={"100%"}
          allow={"encripted-media"}
        />
      </SpotifyWrapper>
    );
  }

  // YouTube Embed
  if (url.includes("youtube") || url.includes("youtu.be")) {
    const videoId = getYoutubeVideoId(url);
    if (!videoId) return <></>;
    return (
      <YoutubeWrapper>
        <iframe
          src={`https://www.youtube.com/embed/${videoId}`}
          width={"100%"}
          height={"100%"}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </YoutubeWrapper>
    );
  }

  if (!data || !data.fetchLinkData) return <></>;

  return (
    <Container>
      <Link href={getUrl(data.fetchLinkData.url ?? url, loading)} target="_blank" rel="noreferrer">
        {data.fetchLinkData.image && data.fetchLinkData.image.includes("http") ? (
          <ImageWithFallback
            src={data.fetchLinkData.image}
            alt="This image could not be loaded"
            fallbackSrc={"/icons/link.png"}
            width={384}
            height={144}
          />
        ) : (
          <Image src={"/icons/link.png"} width={16} height={16} alt="link icon" />
        )}
        <TextWrapper>
          <Text>
            {data.fetchLinkData.title ?? data.fetchLinkData.url ?? url ?? "External Link - Exercise Caution"}
            {" - "}
            {data.fetchLinkData.url && <StyledSpan>{data.fetchLinkData.url}</StyledSpan>}
          </Text>
          {data.fetchLinkData.description && <Subtext>{data.fetchLinkData.description}</Subtext>}
        </TextWrapper>
      </Link>
    </Container>
  );
}
