import styled from "styled-components";
import { RankingItemFragment } from "../../../../gql/types";
import Heading from "../../../shared/Heading";
import Text from "../../../shared/Text";

type RankingItemProps = {
  item: RankingItemFragment;
};

const Container = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;

  h1 {
    color: var(--color-gray-5);
  }
`;

export default function RankingItem({ item }: RankingItemProps) {
  return (
    <Container>
      <HeadingContainer>
        <Heading>{item.order}</Heading>
      </HeadingContainer>
      <Text>{item.title}</Text>
    </Container>
  );
}
