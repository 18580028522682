import AddReaction from "./AddReaction";
import Reaction from "./Reaction";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { MessageDetailsFragment, UserDetailsFragment } from "../../../gql/types";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  gap: 0.5rem;
  font-style: normal;
  margin-top: 0.5rem;
`;

const ReactionsSkeletons = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const ReactionsSkeleton = styled(Skeleton)`
  width: 48px;
  height: 28px;
  border-radius: 9999px;
`;

function ReactionList({
  bunchMembers,
  displayMode = false,
  message,
  reactions = [],
}: {
  bunchMembers: UserDetailsFragment[];
  displayMode: boolean;
  message: MessageDetailsFragment;
  reactions: MessageDetailsFragment["reactions"];
}) {
  if (!message) {
    return null;
  }

  return (
    <Container>
      {!reactions.length && (
        <ReactionsSkeletons>
          <ReactionsSkeleton />
          <ReactionsSkeleton />
        </ReactionsSkeletons>
      )}
      {message &&
        reactions.length > 0 &&
        reactions.map((reaction, index) => (
          <Reaction
            key={`${message.messageId}-${index}`}
            displayMode={displayMode}
            bunchMembers={bunchMembers}
            message={message}
            messageId={message.messageId}
            reaction={reaction}
            hideDidReact={displayMode}
          />
        ))}
      {!displayMode && <AddReaction message={message} />}
    </Container>
  );
}

export default ReactionList;
