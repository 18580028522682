import sortBy from "lodash.sortby";
import { ContentObject } from "../gql/types";

const componentify = (content: ContentObject) => {
  let contentText = content.text;
  let lastIndex = 0;
  let results = [];
  let bunchMentions = content.bunchMentions ?? [];
  let hashtags = content.hashtags ?? [];
  let urls = content.urls ?? [];
  let userMentions = content.userMentions ?? [];
  let components = sortBy([...bunchMentions, ...hashtags, ...urls, ...userMentions], ["startIndex"]);

  // if no text, early exit
  if (!contentText) return null;

  // If no components, early exit.
  if (!components || components.length === 0) {
    results.push({ type: "text", content: contentText });
    return results;
  }

  let startIndex = components[0]?.startIndex ?? 0;

  // Setup results if message doesn't begin with a component.
  const begin = contentText.substring(lastIndex, startIndex);
  if (begin !== "") results.push({ type: "text", content: begin });

  // Build display components for entire string.
  lastIndex = startIndex;
  const length = contentText.length ?? 0;
  while (lastIndex < length) {
    // Loop through components.
    for (let i = 0; i < components.length; i++) {
      let component = components[i];
      if (!component || component.startIndex === null || component.endIndex === null) return;

      if (lastIndex !== component.startIndex) {
        const begin = contentText.substring(lastIndex, component.startIndex ?? 0);
        if (begin !== "") results.push({ type: "text", content: begin });
        lastIndex = component.startIndex;
      }

      const text = contentText.substring(lastIndex, component.endIndex + 1);
      if (text !== "") results.push({ type: component.type, id: component.content, content: text });
      lastIndex = component.endIndex + 1;
    }

    // Add content from last component's end through end of message.
    if (lastIndex < contentText.length) {
      const end = contentText.substring(lastIndex, contentText.length);
      if (end !== "") results.push({ type: "text", content: end });
      lastIndex = contentText.length;
    }
  }

  return results;
};

export default componentify;
