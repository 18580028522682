import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

type AnimatedCheckboxProps = {
  checked: boolean;
  disabled: boolean;
  onSelect?: Function;
};

const OuterContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem;
  height: 1.75rem;
  padding: 4px;
`;

const Container = styled.div<{ checked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: ${({ checked }) => (checked ? "var(--color-primary)" : "var(--color-background)")};
  outline: ${({ checked }) => (checked ? "var(--color-gray-6) solid 4px" : "var(--color-gray-5) solid 1px")};
  border: 1px solid ${({ checked }) => (checked ? "var(--color-primary)" : "var(--color-gray-4)")};
  border-radius: 50%;
  transition: all 150ms linear;
`;

const BlueCircle = styled.div<{ draw: boolean }>`
  background: var(--color-primary);
  border-radius: 50%;
  width: ${({ draw }) => (draw ? "100%" : "0%")};
  height: ${({ draw }) => (draw ? "100%" : "0%")};
  transition: all 150ms linear;
`;

const Checkmark = styled.svg<{ draw: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  stroke-dasharray: 15;
  stroke-dashoffset: ${({ draw }) => (draw ? "0" : "15")};
  transition: all 150ms linear;
`;

export default function AnimatedCheckbox({ checked, disabled, onSelect }: AnimatedCheckboxProps) {
  const [selected, setSelected] = useState(checked);
  const [drawCheck, setDrawCheck] = useState(false);
  const circleRef = useRef<HTMLElement>();
  const checkRef = useRef<HTMLElement>();

  const handleClick = (e: MouseEvent) => {
    if (disabled) return;
    e.stopPropagation();
    selected ? backward() : forward();
    onSelect?.();
  };

  const animate = useCallback((element: HTMLElement, callback: Function) => {
    function handleTransitionEnd() {
      callback();
    }
    element.addEventListener("transitionend", handleTransitionEnd, { once: true });
  }, []);

  const forward = useCallback(async () => {
    if (!circleRef.current) return;
    setSelected(true);
    animate(circleRef.current, () => {
      setDrawCheck(true);
    });
  }, [circleRef, animate]);

  const backward = async () => {
    if (!checkRef.current) return;
    setDrawCheck(false);
    animate(checkRef.current, () => {
      setSelected(false);
    });
  };

  useEffect(() => {
    if (!checked) return;
    setDrawCheck(true);
  }, [checked, forward]);

  return (
    <OuterContainer onClick={handleClick}>
      <Container ref={circleRef} checked={selected}>
        <BlueCircle draw={selected} />
      </Container>
      <Checkmark
        draw={drawCheck}
        ref={checkRef}
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
      >
        <path
          d="M2.5957 6.50418L5.59569 9.50417L10.8457 4.2542"
          stroke="#F7F7FC"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Checkmark>
    </OuterContainer>
  );
}
