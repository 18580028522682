export type MessageAction = {
  key: string;
  label: string;
  image: string;
  needAuthor: boolean;
  needCreator: boolean;
};

const messageActions: MessageAction[] = [
  { key: "react", label: "React", image: "/icons/addReaction.png", needAuthor: false, needCreator: false },
  { key: "copy", label: "Copy", image: "/icons/copy.png", needAuthor: false, needCreator: false },
  { key: "reply", label: "Reply", image: "/icons/chat.png", needAuthor: false, needCreator: false },
  { key: "share", label: "Share", image: "/icons/shareEnvelope.png", needAuthor: false, needCreator: false },

  // Destructive Actions
  {
    key: "block",
    label: "Block User",
    image: "/icons/blockUser.png",
    needAuthor: false,
    needCreator: false,
  },
  {
    key: "unblock",
    label: "Unblock User",
    image: "/icons/blockUser.png",
    needAuthor: false,
    needCreator: false,
  },
  { key: "delete", label: "Delete", image: "/icons/delete.png", needAuthor: true, needCreator: false },
  { key: "moderate", label: "Moderate", image: "/icons/moderate.png", needAuthor: false, needCreator: true },
  { key: "unmoderate", label: "Unmoderate", image: "/icons/moderate.png", needAuthor: false, needCreator: true },
  { key: "report", label: "Report", image: "/icons/report.png", needAuthor: false, needCreator: false },
];

export default messageActions;
