import Image from "next/image";
import Subtext from "../../shared/Subtext";
import styled from "styled-components";
import uniqBy from "lodash.uniqby";
import { MessageDetailsFragment } from "../../../gql/types";

const Container = styled.button<{ isHovering: boolean; displayMode: boolean }>`
  align-items: center;
  background: var(--color-gray-6);
  border-radius: 0.75rem;
  display: flex;
  gap: 0.5rem;
  margin-right: auto;
  margin-top: 0.5rem;
  padding: 0.25rem 0.5rem;
  cursor: default;

  &:hover {
    ${({ displayMode }) =>
      !displayMode &&
      `
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: var(--color-action);
  `}
  }

  ${({ isHovering, displayMode }) =>
    isHovering &&
    !displayMode &&
    `
    background: var(--color-gray-6);
  `}
`;

const ParticipantIcons = styled.div`
  display: flex;
`;

const ParticipantIcon = styled(Image)`
  width: 2rem;
  height: 2rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid var(--color-gray-6);

  &:not(:first-child) {
    margin-left: -0.75rem;
  }
`;

const ReplyCount = styled(Subtext)`
  color: var(--color-gray-3);
  font-size: 0.875rem;
`;

const PARTICIPANT_IMAGE_LIMIT = 5;

export default function ThreadPreview({
  thread = [],
  isHovering = false,
  displayMode = false,
  onClick = undefined,
  replyCount,
}: {
  thread: MessageDetailsFragment[];
  isHovering?: boolean;
  displayMode?: boolean;
  onClick?: () => void;
  replyCount: number;
}) {
  const participants = uniqBy(
    thread.filter((x) => !!x.parentMessageId).map((reply) => reply.user),
    "userId",
  ).slice(0, PARTICIPANT_IMAGE_LIMIT);

  if (replyCount < 1) {
    return null;
  }

  return (
    <Container isHovering={isHovering} displayMode={displayMode} onClick={() => !displayMode && onClick && onClick()}>
      <ParticipantIcons>
        {participants.map((user, index) => {
          if (!user?.profilePictureUrl) return null;

          return (
            <ParticipantIcon
              key={`ThreadPreview-Participant-${index}`}
              src={user.profilePictureUrl}
              alt=""
              width={16}
              height={16}
            />
          );
        })}
      </ParticipantIcons>
      <ReplyCount>
        {replyCount} {replyCount === 1 ? "reply" : "replies"}
      </ReplyCount>
    </Container>
  );
}
