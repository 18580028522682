export const GRAY1 = "Gray1";
export const GRAY2 = "Gray2";
export const GRAY3 = "Gray3";
export const GRAY4 = "Gray4";
export const GRAY5 = "Gray5";
export const GRAY6 = "Gray6";
export const GRAY7 = "Gray7";
export const PRIMARY = "Primary";
export const MILK = "Milk";
export const BACKGROUND = "Background";
export const INVERTED_BACKGROUND = "InvertedBackground";

export type MerlotColorAlias =
  | typeof GRAY1
  | typeof GRAY2
  | typeof GRAY3
  | typeof GRAY4
  | typeof GRAY5
  | typeof GRAY6
  | typeof GRAY7
  | typeof PRIMARY
  | typeof MILK
  | typeof BACKGROUND
  | typeof INVERTED_BACKGROUND;

//Type casting to hide type errors since merlot will on give us type 'string' not type 'Gray1'
export const LIGHT_MODE_COLORS: Record<MerlotColorAlias, string> = {
  [GRAY1]: "#313131",
  [GRAY2]: "#4f4f4f",
  [GRAY3]: "#828282",
  [GRAY4]: "#bdbdbd",
  [GRAY5]: "#e0e0e0",
  [GRAY6]: "#f2f2f2",
  [GRAY7]: "#fafafa",
  [PRIMARY]: "#0000ff",
  [MILK]: "#fbfbff",
  [BACKGROUND]: "#ffffff",
  [INVERTED_BACKGROUND]: "#1e1e1e",
};

//Type casting to hide type errors since merlot will on give us type 'string' not type 'Gray1'
export const DARK_MODE_COLORS: Record<MerlotColorAlias, string> = {
  [GRAY1]: "#fafafa",
  [GRAY2]: "#f2f2f2",
  [GRAY3]: "#e0e0e0",
  [GRAY4]: "#bdbdbd",
  [GRAY5]: "#828282",
  [GRAY6]: "#4f4f4f",
  [GRAY7]: "#313131",
  [PRIMARY]: "#419bf9",
  [MILK]: "#fbfbff",
  [BACKGROUND]: "#1e1e1e",
  [INVERTED_BACKGROUND]: "#ffffff",
};
