import Image from "next/image";
import styled from "styled-components";
import { useMerlotColorAlias } from "../../utilities/hooks/useMerlotColorAlias";
import { MerlotColorAlias } from "../../utilities/constants/colors";
import { PillDetailsFragment } from "../../gql/types";

type Props = {
  tagItem: PillDetailsFragment;
};

const TagItemPill = styled.div<{
  backgroundColor?: string;
  borderColor?: string;
}>`
  align-items: center;
  background: ${(props) => props.backgroundColor};
  border: 1px solid transparent;
  border-color: ${(props) => props.borderColor};
  border-radius: 9999px;
  display: flex;
  padding: 0.15rem 0.75rem;
`;

const TagImage = styled(Image)`
  margin-right: 0.3rem;
`;

const TagItemText = styled.div<{ textColor?: string }>`
  color: ${(props) => props.textColor};
  font-size: 0.75rem;
  font-weight: 600;
`;

export default function TagItem({ tagItem }: Props) {
  const backgroundColor = useMerlotColorAlias(
    tagItem.backgroundColor as MerlotColorAlias,
    tagItem.backgroundColorAlpha,
  );
  const borderColor = useMerlotColorAlias(tagItem.borderColor as MerlotColorAlias, tagItem.borderColorAlpha);
  const textColor = useMerlotColorAlias(tagItem.textColor as MerlotColorAlias, tagItem.textColorAlpha);
  const iconUrl = tagItem.icon.lightModeUrl ?? tagItem.icon.url;

  return (
    <TagItemPill backgroundColor={backgroundColor} borderColor={borderColor}>
      {iconUrl && <TagImage src={iconUrl} alt={tagItem.text} width={16} height={16} />}
      <TagItemText textColor={textColor}>{tagItem.text}</TagItemText>
    </TagItemPill>
  );
}
