import styled from "styled-components";
import Image from "next/image";
import UiButton from "../../shared/UiButton";
import { MessageDetailsFragment } from "../../../gql/types";

const MessageFooterContainer = styled.div`
  align-items: center;
  border-top: 1px solid var(--color-gray-5);
  display: flex;
  justify-content: space-between;
  margin: 0 0.5rem;
  padding: 0.75rem 0.25rem 0.25rem;
  width: 98%;
`;

const FooterProfilePictureWrapper = styled.div`
  align-items: center;
  color: var(--color-gray-4);
  display: flex;
  margin-right: 0.5rem;
`;

const FooterProfilePictureImage = styled(Image)<{ index: number }>`
  border: 1px solid var(--color-milk);
  border-radius: 50%;
  z-index: var(--z-above);

  &.second {
    margin-left: -4px;
    z-index: ${({ index }) => (index ? `calc(var(--z-above) - ${index})` : "var(--z-above)")};
  }
`;

const FooterCaption = styled.div`
  color: var(--color-gray-4);
  flex: 1;
  font-size: 0.8rem;
`;

const FooterButton = styled(UiButton)``;

export default function MessageFooter({ isThread, message }: { isThread: boolean; message: MessageDetailsFragment }) {
  return (
    <MessageFooterContainer>
      <FooterProfilePictureWrapper>
        {message.footer.mediaItems &&
          message.footer.mediaItems.length > 0 &&
          message.footer.mediaItems.map((picture, index) => {
            if (index < 1) {
              return (
                <FooterProfilePictureImage
                  key={index}
                  index={index}
                  alt={"Booster Profile"}
                  src={picture.url}
                  width={28}
                  height={28}
                />
              );
            } else {
              return (
                <FooterProfilePictureImage
                  key={index}
                  className={"second"}
                  index={index}
                  alt={"Booster Profile"}
                  src={picture.url}
                  width={22}
                  height={22}
                />
              );
            }
          })}
      </FooterProfilePictureWrapper>
      {message.footer.messageFooterCaption && <FooterCaption>{message.footer.messageFooterCaption}</FooterCaption>}
      {!isThread && message.footer.messageFooterButton && <FooterButton button={message.footer.messageFooterButton} />}
    </MessageFooterContainer>
  );
}

