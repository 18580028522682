import { gql, TypedDocumentNode } from "@apollo/client";
import { MESSAGE_DETAILS } from "../fragments";
import { CastPollVoteMutation, CastPollVoteMutationVariables } from "../types";

export const CAST_POLL_VOTE: TypedDocumentNode<CastPollVoteMutation, CastPollVoteMutationVariables> = gql`
  ${MESSAGE_DETAILS}
  mutation castPollVote($pollOptionId: ID!) {
    castPollVote(pollOptionId: $pollOptionId) {
      ...MessageDetails
    }
  }
`;
