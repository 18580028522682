import Subtext from "../../shared/Subtext";
import styled from "styled-components";
import { PROFILE } from "../../auth/scout/PhotoUploader";
import { useAnalyticsContext } from "../../state/AnalyticsProvider";
import { useRightSidebarContext } from "../../state/RightSidebar";
import { useRouter } from "next/router";

const StyledText = styled.span`
  color: var(--color-primary);
`;

export default function Username({
  at = false,
  displayMode = false,
  isExternal = false,
  user,
  ...props
}: {
  at?: boolean;
  displayMode?: boolean;
  isExternal?: boolean;
  prefix?: string;
  user?: any;
}) {
  const { displayPanel } = useRightSidebarContext();
  const router = useRouter();
  const { pushTrackEvent } = useAnalyticsContext();

  if (user?.username) {
    return (
      <button
        onClick={() => {
          if (displayMode) return;
          pushTrackEvent("Tapped User Profile from Message", {
            userId: user.userId ?? "no id",
            username: user.username ?? "no username",
          });
          isExternal ? router.replace(`/${user.username}`) : displayPanel(PROFILE, user.userId);
        }}
        {...props}
      >
        <StyledText>
          {at ? "@" : ""}
          {user?.username ? user?.username : "someone"}
        </StyledText>
      </button>
    );
  }

  return (
    <Subtext>someone</Subtext>
  );
}
