import Image from "next/image";
import { useCallback } from "react";
import styled from "styled-components";
import { FrameButtonType, FrameProperties } from "../../../pages/api/frame";

type FrameComponentProps = {
  frame: FrameProperties;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid var(--color-gray-5);
  width: 32rem;
  background: var(--color-gray-7);
`;

const Preview = styled.div<{ aspectRatio?: string }>`
  position: relative;
  aspect-ratio: ${({ aspectRatio }) => (aspectRatio ? aspectRatio.split(":").join(" / ") : "1.91 / 1")};
  border-bottom: 1px solid var(--color-gray-5);
`;

const InputButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25rem 1rem;
  gap: 0.25rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.25rem 0;
`;

const FrameButton = styled.button`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-gray-5);
  border-radius: 0.5rem;
  background: var(--color-gray-6);
  width: 100%;
  height: 2rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  transition: all var(--animation-timing-quick) var(--timing-easeOutQuart);
  cursor: pointer;

  &:hover {
    background: var(--color-gray-5);
    border-color: var(--color-gray-4);
  }
`;

export default function FrameComponent({ frame }: FrameComponentProps) {
  const handleFrameButtonClick = useCallback(async (button: FrameButtonType) => {
    switch (button.action) {
      case "link":
        window.open(button.target, "_blank");
        break;
    }
  }, []);

  return (
    <Container>
      <Preview aspectRatio={frame.image?.aspect_ratio}>
        {frame.image && <Image src={frame.image.url} fill alt="Frame Preview" />}
      </Preview>
      <InputButtonContainer>
        <ButtonsContainer>
          <>
            {frame.buttons?.map((b, i) => {
              return (
                b.action === "link" && (
                  <FrameButton key={`frame-button-${i}`} onClick={async () => handleFrameButtonClick(b)}>
                    {b.label}
                    {b.action === "link" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        width={15}
                        height={15}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                        />
                      </svg>
                    )}
                  </FrameButton>
                )
              );
            })}
          </>
        </ButtonsContainer>
      </InputButtonContainer>
    </Container>
  );
}
