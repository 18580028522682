import { Dispatch, MouseEvent, SetStateAction } from "react";
import styled from "styled-components";
import { Grade, GradeColor, RankingDetailsFragment } from "../../../../gql/types";
import Text from "../../../shared/Text";

type GradeButtonProps = {
  ranking: RankingDetailsFragment;
  checked: boolean;
  onSelect: (clientRankingId: string, grade: string, rankingId: string) => void;
  grade: Grade;
  votes: number;
  disabled: boolean;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  textColor: GradeColor;
  borderColor: GradeColor;
  backgroundColor: GradeColor;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
`;

const GradeContainer = styled.div<{ selected: boolean; textColor: string; borderColor: string; bgColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.75rem;
  width: 1.75rem;

  color: ${({ textColor }) => textColor};
  background: ${({ bgColor }) => bgColor};
  outline: ${({ selected }) => (selected ? "5px" : "1.5px")} solid ${({ borderColor }) => borderColor};
  border-radius: 50%;

  transition: all 200ms linear;

  font-weight: 900;
`;

const StyledText = styled(Text)`
  color: var(--color-gray-2);
  font-weight: 600;
`;

export default function GradeButton({
  checked,
  onSelect,
  disabled,
  setDisabled,
  grade,
  ranking,
  votes,
}: GradeButtonProps) {
  // const isDarkMode = useTheme();

  function getCorrectColor(gradeColor: GradeColor) {
    const selectedColor = checked ? gradeColor.selected : gradeColor.unselected;
    // @TODO darkmode
    // return isDarkMode ? selectedColor.dark : selectedColor.light;
    return selectedColor.light;
  }

  function handleClick(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    if (disabled) return;
    setDisabled(true);
    onSelect(ranking.clientRankingId, grade.title, ranking.rankingId);
  }

  return (
    <Wrapper>
      <GradeContainer
        selected={checked}
        onClick={handleClick}
        borderColor={getCorrectColor(grade.borderColor)}
        textColor={getCorrectColor(grade.textColor)}
        bgColor={getCorrectColor(grade.backgroundColor)}
        onTransitionEnd={() => {
          setDisabled(false);
        }}
      >
        {grade.title}
      </GradeContainer>
      <StyledText fontSize="0.875rem">{votes}</StyledText>
    </Wrapper>
  );
}
