import AddReaction from "./AddReaction";
import MessageActionItem from "./MessageActionItem";
import messageActions, { MessageAction } from "../../../utilities/constants/messageActions";
import styled from "styled-components";
import { MessageDetailsFragment } from "../../../gql/types";
import { copyToClipboard, handleCaughtError } from "../../../utilities";
import { displayToast } from "../../shared/toasts";
import { useAnalyticsContext } from "../../state/AnalyticsProvider";
import { useAuthContext } from "../../state/AuthProvider";
import { useBunchesContext } from "../../state/BunchesProvider";
import { useCallback, useEffect, useState } from "react";
import { useMessagesContext } from "../../state/MessagesProvider";
import { THREAD, useRightSidebarContext } from "../../state/RightSidebar";
import { useUserContext } from "../../state/UserProvider";

const MessageActionItemList = styled.div`
  display: flex;
  gap: 0.25rem;
  border-radius: 0.75rem;
  box-shadow:
    0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  background: var(--color-gray-7);
  border: 1px solid var(--color-gray-5);
  padding: 0.25rem;
  position: absolute;
  top: -1rem;
  right: 0.25rem;
  z-index: var(--z-chat-actions);
`;

const ReactionAction = styled(AddReaction)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.125rem;
  background: none;
  border: 1px solid transparent;
  width: 1.5rem;
  height: 1.5rem;

  &:hover {
    background: var(--color-background);
    border-color: var(--color-gray-5);
  }
`;

function MessageActions({ message, creatorId, ...props }: { message: MessageDetailsFragment; creatorId?: string }) {
  let possibleActions = messageActions || [];
  const { currentUser } = useAuthContext();
  const { displayPanel, closePanel } = useRightSidebarContext();
  const { setActiveThreadId, activeThreadId } = useBunchesContext();
  const { startNewThread, deleteMessage, moderateMessage, unmoderateMessage, reportMessage } =
    useMessagesContext();
  const { pushTrackEvent } = useAnalyticsContext();
  const { blockUser, unblockUser, blockedUsers } = useUserContext();

  const [actionIsBusy, setActionIsBusy] = useState<MessageAction["key"] | null>(null);
  const [userIsBlocked, setUserIsBlocked] = useState(false);

  useEffect(() => {
    if (!message?.userId || !blockedUsers || !blockedUsers.length) {
      return;
    }

    const isBlocked = blockedUsers?.find((user) => user.userId === message.userId);

    setUserIsBlocked(!!isBlocked);
  }, [blockedUsers, message]);

  // Wrap the message action event handler for error handling purposes
  const onMessageAction = async (action: MessageAction) => {
    try {
      await handleMessageAction(action);
      setActionIsBusy(null);
    } catch (err: any) {
      await handleCaughtError(err, err.message);
      setActionIsBusy(null);
    }
  };

  const handleMessageAction = useCallback(
    async (action: MessageAction) => {
      if (!message || !currentUser) return;

      setActionIsBusy(action.key);

      switch (action.key) {
        case "block":
          pushTrackEvent("Blocked User", {
            userId: message.userId,
            username: message.user.username,
          });

          const blockedUser = await blockUser(message.userId, true);

          await displayToast(`Blocked user: ${blockedUser?.username}`, { type: "success" });
          setUserIsBlocked(true);
          break;
        case "unblock":
          pushTrackEvent("Unblocked User", {
            userId: message.userId,
            username: message.user.username,
          });

          const unblockedUser = await unblockUser(message.userId);

          await displayToast(`Unblocked user: ${unblockedUser?.username}`, { type: "success" });
          setUserIsBlocked(false);
          break;
        case "copy":
          await copyToClipboard(message.content.previewText ?? "Someone sent a message.");

          await displayToast("Message copied", { type: "success" });
          break;
        case "reply":
          if (message.replyCount > 0) {
            setActiveThreadId(message.messageId);
            displayPanel(THREAD);
          } else {
            startNewThread(message);
            setActiveThreadId(message.messageId);
            displayPanel(THREAD);
          }
          break;
        case "delete":
          pushTrackEvent("Deleted Message", { messageId: message.messageId });
          await deleteMessage(message);

          if (activeThreadId === message.messageId) {
            setActiveThreadId(null);
            closePanel();
          }
          break;
        case "moderate":
          pushTrackEvent("Moderated Message", {
            messageId: message.messageId,
            messageAuthorId: message.user.userId,
            messageAuthorUsername: message.user.username,
          });
          await moderateMessage({ messageId: message.messageId, userId: currentUser.userId });
          break;
        case "boost":
          break;
        case "react":
          // Do nothing. Handled in AddReaction component.
          break;
        case "report":
          pushTrackEvent("Reported Message", {
            messageId: message.messageId,
            messageAuthorId: message.user.userId,
            messageAuthorUsername: message.user.username,
          });

          const reportResponse = await reportMessage({ userId: currentUser.userId, messageId: message.messageId });

          if (reportResponse) {
            await displayToast(`Reported message from user: ${reportResponse.user?.username}`, {
              type: "success",
            });
          }
          break;
        case "share":
          pushTrackEvent("Shared Message", { messageId: message.messageId });
          window.open(`https://onbunches.com/message/${message.shareCode}`, "_blank");
          break;
        case "unmoderate":
          pushTrackEvent("Unmoderated Message", {
            messageId: message.messageId,
            messageAuthorId: message.user.userId,
            messageAuthorUsername: message.user.username,
          });
          await unmoderateMessage({ messageId: message.messageId, userId: currentUser.userId });
          break;
        default:
          break;
      }

      setActionIsBusy(null);
    },
    [
      activeThreadId,
      blockUser,
      closePanel,
      currentUser,
      deleteMessage,
      displayPanel,
      message,
      moderateMessage,
      pushTrackEvent,
      reportMessage,
      setActiveThreadId,
      startNewThread,
      unblockUser,
      unmoderateMessage,
    ],
  );

  if (!currentUser || !message) {
    return null;
  }

  const isCreator = currentUser.userId === creatorId || currentUser.isAdmin;
  const isAuthor = currentUser.userId === message.userId || currentUser.isAdmin;

  // Creator Actions
  if (!isCreator) {
    possibleActions = possibleActions.filter((action) => !action.needCreator);
  }

  // Author Actions
  if (!isAuthor) {
    possibleActions = possibleActions.filter((action) => !action.needAuthor);
  }
  if (isAuthor && !currentUser.isAdmin) {
    possibleActions = possibleActions.filter((action) => action.key !== "block" && action.key !== "unblock");
  }
  if (isAuthor && !currentUser.isAdmin) {
    possibleActions = possibleActions.filter((action) => action.key !== "moderate");
  }
  if (isAuthor && !currentUser.isAdmin) {
    possibleActions = possibleActions.filter((action) => action.key !== "report");
  }
  if (isAuthor && !currentUser.isAdmin) {
    possibleActions = possibleActions.filter((action) => action.key !== "unmoderate");
  }

  // Hide reply for inline messages
  if (message.type === "inline" || message.parentMessageId || message.messageId === activeThreadId) {
    possibleActions = possibleActions.filter((action) => action.key !== "reply");
  }

  // Block actions
  if (userIsBlocked) {
    possibleActions = possibleActions.filter((action) => action.key !== "block");
  } else {
    possibleActions = possibleActions.filter((action) => action.key !== "unblock");
  }

  // Moderate Actions
  if (message.isModerated) {
    possibleActions = possibleActions.filter((action) => action.key !== "moderate");
  }
  if (!message.isModerated) {
    possibleActions = possibleActions.filter((action) => action.key !== "unmoderate");
  }

  if (!message) {
    return null;
  }

  return (
    <MessageActionItemList {...props}>
      {possibleActions &&
        possibleActions.length > 0 &&
        possibleActions.map((action) => {
          if (action.key === "react") {
            return <ReactionAction key={action.key} message={message} />;
          }

          return (
            <MessageActionItem
              key={action.key}
              isBusy={actionIsBusy === action.key}
              action={action}
              actionHandler={onMessageAction}
            />
          );
        })}
    </MessageActionItemList>
  );
}

export default MessageActions;
