import styled from "styled-components";

const Outer = styled.div`
  background: var(--color-gray-5);
  border-radius: 9999px;
  height: 0.5rem;
  margin: auto;
`;

const Inner = styled.div`
  background: var(--color-charcoal);
  border-radius: 9999px;
  height: 0.5rem;
`;

export default function ProgressBar({
  progress,
  width = 200,
  fluid = false,
}: {
  progress: number;
  width?: number;
  fluid?: boolean;
}) {
  return (
    <Outer style={{ width: fluid ? "100%" : `${width}px` }}>
      <Inner style={{ width: fluid ? `${progress * 100}%` : `${progress * width}px` }}></Inner>
    </Outer>
  );
}
