import { useEffect, useState } from "react";
import { DARK_MODE_COLORS, LIGHT_MODE_COLORS, MerlotColorAlias } from "../constants/colors";
import tinycolor from "tinycolor2";

export const useMerlotColorAlias = (merlotColor?: MerlotColorAlias, alpha?: number) => {
  const [color, setColor] = useState<string>();
  // TODO: Needs to be fixed when we allow darkmode ...
  // const isDarkMode = useTheme();

  useEffect(() => {
    if (!merlotColor) return;
    let tc;
    // TODO: add dark mode
    // if (isDarkMode) {
    //   tc = tinycolor(DARK_MODE_COLORS[merlotColor]);
    // } else {
    //   tc = tinycolor(LIGHT_MODE_COLORS[merlotColor]);
    // }
    tc = tinycolor(LIGHT_MODE_COLORS[merlotColor]);
    if (alpha) tc.setAlpha(alpha);
    setColor(tc.toHexString());
  }, [merlotColor, alpha]);

  return color;
};
