import { useMutation } from "@apollo/client";
import Image from "next/image";
import { MouseEvent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { GRADE_RANKING } from "../../../../gql/ranking/gradeRanking";
import { MessageDetailsFragment } from "../../../../gql/types";
import { handleCaughtError } from "../../../../utilities";
import SectionHeading from "../../../portal/SectionHeading";
import Text from "../../../shared/Text";
import { useAnalyticsContext } from "../../../state/AnalyticsProvider";
import { RANKING_DETAILS, useRightSidebarContext } from "../../../state/RightSidebar";
import GradeButton from "./GradeButton";
import RankingItem from "./RankingItem";

type RankingMessageContentProps = {
  message: MessageDetailsFragment;
  displayOnly: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  margin-top: 0.5rem;
  border-radius: 0.75rem;
  background: var(--color-gray-7);
  max-width: 400px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleWithIcon = styled.div`
  display: flex;
  gap: 0.25rem;

  p {
    color: var(--color-gray-4);
    font-family: var(--font-family-headline);
    font-size: 0.75rem;
  }
`;

const ViewGradesButton = styled.button`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  font-family: var(--font-family-headline);
  font-size: 0.75rem;
`;

const MediaItemContainer = styled.div`
  border-radius: 0.75rem;
  overflow: hidden;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const GradeButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 0.25rem;
`;

export default function RankingMessageContent({ message, displayOnly = false }: RankingMessageContentProps) {
  // GQL
  const [gradeRankingMutation] = useMutation(GRADE_RANKING);

  // Context Providers
  const { pushTrackEvent } = useAnalyticsContext();
  const { displayPanel } = useRightSidebarContext();

  // State
  const [gradeList, setGradeList] = useState(message.ranking.grades);
  const [loading, setLoading] = useState(false);

  const gradeRanking = useCallback(
    async (clientRankingId: string, grade: string, rankingId: string) => {
      pushTrackEvent("[Ranking] Graded Ranking");
      try {
        if (!gradeList) return;

        let prevGrade = gradeList.find((g) => g.isSelected);
        let selectedGrade = gradeList.find((g) => g.title === grade);

        if (!selectedGrade) return;

        let unSelect = prevGrade?.title === selectedGrade.title;
        if (unSelect) {
          selectedGrade = { ...selectedGrade, isSelected: false, count: selectedGrade.count - 1 };
          prevGrade = undefined;
        } else {
          selectedGrade = { ...selectedGrade, isSelected: true, count: selectedGrade.count + 1 };
          prevGrade = prevGrade ? { ...prevGrade, isSelected: false, count: prevGrade.count - 1 } : undefined;
        }

        const newGradeList = gradeList
          .map((g) => (g.title === selectedGrade?.title ? selectedGrade : g))
          .map((g) => (g.title === prevGrade?.title ? prevGrade : g));

        setGradeList(newGradeList);

        await gradeRankingMutation({
          variables: { clientRankingId, grade: unSelect ? "" : grade, rankingId },
        });
      } catch (err) {
        handleCaughtError(err);
      }
    },
    [gradeRankingMutation, gradeList, pushTrackEvent],
  );

  useEffect(() => {
    if (message && message.ranking && message.ranking.grades) {
      setGradeList(message.ranking.grades);
    }
  }, [message]);

  if (!message || !message.ranking) return null;

  return (
    <>
      <Container
        onClick={(e: MouseEvent<HTMLElement>) => {
          if (displayOnly) return;
          e.stopPropagation();
          pushTrackEvent("[Ranking] Tapped View Grades");
          displayPanel(RANKING_DETAILS, message.ranking);
        }}
      >
        <Header>
          <TitleWithIcon>
            <Image src="/icons/ranking.svg" width="16" height="16" alt="" />
            <Text>RANKING</Text>
          </TitleWithIcon>
          <ViewGradesButton>
            VIEW GRADES
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 17 17" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.2922 7.79607L11.2111 6.77607C11.0389 6.60229 11.0389 6.3214 11.2111 6.14762C11.3837 5.97384 11.6631 5.97384 11.8353 6.14762L13.5133 7.76851C13.6379 7.89473 13.7068 8.0614 13.7068 8.24007C13.7068 8.41829 13.6379 8.58584 13.5133 8.71118L11.8353 10.3321C11.6631 10.5058 11.3837 10.5058 11.2111 10.3321C11.0389 10.1587 11.0389 9.8774 11.2111 9.70362L12.2913 8.68495L3.03163 8.71118C2.791 8.71118 2.5957 8.51207 2.5957 8.26673C2.5957 8.0214 2.791 7.82229 3.03163 7.82229L12.2922 7.79607Z"
                fill="var(--color-primary)"
              />
            </svg>
          </ViewGradesButton>
        </Header>
        {!!message.ranking.mediaItem && (
          <MediaItemContainer>
            <Image src={message.ranking.mediaItem.url} alt="" width={200} height={200} layout="responsive" />
          </MediaItemContainer>
        )}
        <Text fontSize="1.125rem" bold>
          {message.ranking.title}
        </Text>
        <ItemsContainer>
          {message.ranking.items &&
            message.ranking.items.map((item, i) => <RankingItem key={item.title + i} item={item} />)}
        </ItemsContainer>
        {gradeList && (
          <>
            <SectionHeading title="grades" divider gap="0.75rem" dividerHeight="3px" fontSize="1.25rem" />
            <GradeButtons>
              {gradeList.map((grade, i) => (
                <GradeButton
                  ranking={message.ranking}
                  key={message.ranking.title + "grade-button" + i}
                  onSelect={gradeRanking}
                  checked={grade.isSelected}
                  disabled={loading || displayOnly}
                  setDisabled={setLoading}
                  grade={grade}
                  votes={grade.count}
                  textColor={grade.textColor}
                  borderColor={grade.borderColor}
                  backgroundColor={grade.backgroundColor}
                />
              ))}
            </GradeButtons>
          </>
        )}
      </Container>
    </>
  );
}
