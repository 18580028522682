import Image from "next/image";
import styled from "styled-components";
import { PollOptionFragmentFragment } from "../../../gql/types";
import AnimatedCheckbox from "../../shared/AnimatedCheckbox";
import ProgressBar from "../../shared/ProgressBar";
import Text from "../../shared/Text";

type PollOptionProps = {
  option: PollOptionFragmentFragment;
  disabled: boolean;
  showResults: boolean;
  isWinning: boolean;
  castPollVote?: (pollOptionId: string) => Promise<void>;
  showCheckbox: boolean;
  progress?: number;
  percentageText?: string;
};

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const OptionInner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.325rem;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 1.75rem;
`;

const ProgressText = styled(Text)`
  font-family: var(--font-family-headline);
  font-weight: 600;
  font-size: 1.25rem;
`;

const ParticipationContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ParticipantIcons = styled.div`
  display: flex;
  align-items: center;
  height: 1.5rem;

  p {
    color: var(--color-gray-3);
    font-size: 0.875rem;

    &:not(:first-child) {
      margin-left: 0.25rem;
    }
  }
`;

const ParticipantIcon = styled(Image)`
  width: 1.5rem;
  height: 1.5rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid var(--color-gray-6);

  &:not(:first-child) {
    margin-left: -0.25rem;
  }
`;

const MedalPill = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  background: #fefce9;
  border: 1px solid var(--color-category-style-secondary);
  border-radius: 0.75rem;
  font-size: 0.875rem;
`;

export default function PollOption({
  option,
  disabled,
  castPollVote,
  showResults,
  isWinning,
  showCheckbox,
  progress = undefined,
  percentageText = undefined,
}: PollOptionProps) {
  return (
    <Container>
      {showCheckbox && (
        <AnimatedCheckbox
          checked={option.isSelected}
          disabled={disabled}
          onSelect={() => castPollVote && castPollVote(option.pollOptionId)}
        />
      )}
      <OptionInner>
        <TitleContainer>
          <Text>{option.title}</Text>
          {showResults && (
            <ProgressText>{`${!!percentageText ? percentageText : option.progress.toFixed(0) + "%"}`}</ProgressText>
          )}
        </TitleContainer>
        <ProgressBar
          progress={progress ? progress / 100 : option.progress / 100}
          height={16}
          fluid
          fillColor={option.isSelected ? "var(--color-primary)" : "#03038C"}
          bgColor="#D1D7F0"
          slantedEnd
          hidden={!showResults}
        />
        <ParticipationContainer>
          <ParticipantIcons>
            {!!option.participationItem.mediaItems &&
              option.participationItem.mediaItems.map((mediaItem, index) => {
                if (!mediaItem || !mediaItem.url) return null;

                return (
                  <ParticipantIcon
                    key={`ThreadPreview-Participant-${index}`}
                    src={mediaItem.url}
                    alt=""
                    width={16}
                    height={16}
                  />
                );
              })}
            <p>{option.participationItem.infoText}</p>
          </ParticipantIcons>
          {isWinning && showResults && <MedalPill>🥇</MedalPill>}
        </ParticipationContainer>
      </OptionInner>
    </Container>
  );
}
