import Image from "next/image";
import { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState, MouseEvent } from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--z-modal-overlay);
`;

const ModalContent = styled.div`
  border-radius: 1rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 1.5rem 2.25rem;
  z-index: var(--z-modal-content);
  display: inline-block;
  overflow: hidden;
  transition-property: all;
  border-radius: 1.5rem;
  box-shadow:
    0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  transition: all 0.2s ease-in-out;
  opacity: 0;

  &.open {
    opacity: 1;
  }
`;

const CloseButton = styled.button`
  display: flex;
  position: absolute;
  background-color: #ffffff;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 9999px;
  cursor: pointer;
  right: 2.25rem;
  top: 1.5rem;
  z-index: var(--z-modal-content);
  backface-visibility: hidden;
  transition:
    transform var(--animation-timing-quick) var(--timing-easeOutQuart),
    color var(--animation-timing-quick) var(--timing-easeOutQuart);
  color: var(--color-gray-3);

  &:hover {
    color: var(--color-primary);
    transform: scale(1.25);
  }
`;

export default function PopupModal({
  children,
  isOpen,
  setIsOpen,
}: {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const contentRef = useRef(null);
  const [contentClass, setContentClass] = useState("");

  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target !== e.currentTarget) return;
    setIsOpen(false);
  };

  const handleContentClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  useEffect(() => {
    setContentClass(isOpen ? "open" : "");
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalContent ref={contentRef} onClick={handleContentClick} className={`${contentClass}`}>
        <CloseButton
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(false);
          }}
        >
          <Image src="/icons/close-lg.svg" width={18} height={18} alt="" />
        </CloseButton>
        {children}
      </ModalContent>
    </ModalOverlay>
  );
}
