import Image from "next/image";
import { useRouter } from "next/router";
import styled from "styled-components";
import { BOOST, BUNCH, PROFILE, THREAD, useRightSidebarContext } from "../state/RightSidebar";
import { useBunchesContext } from "../state/BunchesProvider";
import { useMessagesContext } from "../state/MessagesProvider";

const ButtonWrapper = styled.button`
  align-items: center;
  background-color: var(--color-gray-1);
  border-radius: 31px;
  color: var(--color-milk);
  display: flex;
  font-size: 0.8rem;
  gap: 8px;
  justify-content: center;
  padding: 0.25rem 1rem;
`;

//     source
//     style

export default function UiButton({ button }) {
  const router = useRouter();
  const { setActiveThreadId } = useBunchesContext();
  const { getLocalMessageByMessageId, startNewThread } = useMessagesContext();
  const { displayPanel } = useRightSidebarContext();


  const actOnBunchesUrl = (url: string) => {
    let terms = url.replace("bunches://", "").split("/");

    switch (terms[0]) {
      case "boost":
        let boostMessage = getLocalMessageByMessageId(terms[1]);
        displayPanel(BOOST, boostMessage);
        return;
      case "bunch":
        let bunchId = terms[1];
        displayPanel(BUNCH, bunchId);
        return;
      case "message":
        let messageId = terms[1];
        let message = getLocalMessageByMessageId(terms[1]);
        if (message.replyCount > 0) {
          setActiveThreadId(messageId);
          displayPanel(THREAD);
        } else {
          startNewThread(message);
          setActiveThreadId(messageId);
          displayPanel(THREAD);
        }
        return;
      case "user":
        let userId = terms[1];
        displayPanel(PROFILE, userId)
      case "compose":
      case "dm":
      case "external_dm":
      case "friend_zone":
        // OPEN DM SIDEBAR
        return "";
      case "edit_profile":
        return router.push("edit-profile");
      case "in_app_notifications":
        return router.push("inbox");
      case "discover":
        return router.push("discover");
      case "start":
        return router.push("start");
      case "scoreboard":
        return router.push("scoreboard");
      default:
        return "/b";
    }
  }

  return (
    <ButtonWrapper
      onClick={async () => {
        if (button.action == "route") {
          await actOnBunchesUrl(button.targetUrlString);
        }
      }}
    >
      {button.iconUrlString && <Image src={button.iconUrlString} alt={button.title} height={16} width={16}/>}
      {button.title}
    </ButtonWrapper>
  );
}
