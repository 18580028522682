import { isIOS, isMacOs } from "react-device-detect";
import { DOWNLOAD_URL_ANDROID, DOWNLOAD_URL_IOS } from "./constants/urls";

export function getAppStoreLink() {
  return isIOS || isMacOs ? DOWNLOAD_URL_IOS : DOWNLOAD_URL_ANDROID;
}

const getUrl = (url: string, loading: boolean) => {
  if (loading) return url;
  if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
    return url;
  } else {
    let cleanedUrl = url.replace("ttp://", "").replace("ttps://", "").replace("tps://", "");
    return `http://${cleanedUrl}`;
  }
};

export default getUrl;
