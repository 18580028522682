import { useRouter } from "next/router";
import styled from "styled-components";
import { LightMemberDetailsFragment } from "../../../gql/types";
import { PROFILE } from "../../auth/scout/PhotoUploader";
import ImageWithFallback from "../../shared/ImageWithFallback";
import VerifiedBadge from "../../shared/user/VerifiedBadge";
import { useRightSidebarContext } from "../../state/RightSidebar";

const Button = styled.button<{ size: number; isReadOnly: boolean }>`
  width: ${({ size }) => (size ? size + "px" : "40px")};
  height: ${({ size }) => (size ? size + "px" : "40px")};
  position: relative;
  flex: 1 0 auto;
  ${({ isReadOnly }) => isReadOnly && `cursor: default`};
`;

const Avatar = styled(ImageWithFallback)<{ size: number }>`
  object-fit: cover;
  border-radius: 50%;
  width: ${({ size }) => (size ? size + "px" : "40px")};
  height: ${({ size }) => (size ? size + "px" : "40px")};
`;

const Verified = styled(VerifiedBadge)`
  position: absolute;
  bottom: -0.25rem;
  right: -0.25rem;
`;

export default function UserAvatar({
  user,
  isReadOnly = false,
  external = false,
  action,
  size = 40,
  className,
  showVerified = false,
}: {
  user: LightMemberDetailsFragment;
  isReadOnly?: boolean;
  external?: boolean;
  action?: () => void;
  size?: number;
  className?: string;
  showVerified?: boolean;
}) {
  const { displayPanel } = useRightSidebarContext();
  const router = useRouter();

  if (!user) {
    return null;
  }

  return (
    <Button
      size={size}
      className={className}
      isReadOnly={isReadOnly}
      onClick={() => {
        if (isReadOnly) return;

        action?.();

        external ? router.push(`@${user.username}`) : displayPanel(PROFILE, user.userId);
      }}
    >
      <Avatar
        src={user?.profilePictureUrl}
        fallbackSrc="/img/default_profile.png"
        alt={user?.username + "'s Profile Picture"}
        size={size}
        height={size}
        width={size}
        title={user?.username}
      />
      {(user.verifiedImage || user.isVerified) && showVerified && <Verified user={user} />}
    </Button>
  );
}
