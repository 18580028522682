import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { handleCaughtError } from "../../../../utilities";

const VideoContainer = styled.div`
  border-radius: 0.75rem;
  width: 24rem;
  height: 24rem;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  video {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const PlayButtonOverlay = styled.div<{ paused: boolean }>`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 6rem;
    height: 6rem;
    color: white;
    filter: drop-shadow(2px 4px 1px rgb(0 0 0 / 0.25));
    ${({ paused }) => !paused && `display: none;`}
  }
`;

const LoadingGradient = styled.div`
  position: absolute;
  inset: 0;
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 1000px 640px;

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
`;

export default function VideoWithFallback({ src }: { src: string }) {
  const [paused, setPaused] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const videoPlayerRef = useRef<HTMLVideoElement | null>(null);

  const checkLoaded = useCallback(async (src: string) => {
    try {
      await fetch(src);
      setLoaded(true);
    } catch (err) {
      handleCaughtError(err);
    }
  }, []);

  useEffect(() => {
    if (!videoPlayerRef.current) return;
    const player = videoPlayerRef.current;

    paused ? player.pause() : player.play();

    player.addEventListener("ended", () => setPaused(true));

    return () => player.removeEventListener("ended", () => setPaused(true));
  }, [paused]);

  useEffect(() => {
    checkLoaded(src);
  }, [src, checkLoaded]);

  return (
    <VideoContainer
      onClick={() => {
        setPaused((state) => !state);
      }}
    >
      {loaded ? (
        <>
          <PlayButtonOverlay paused={paused}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
                clipRule="evenodd"
              />
            </svg>
          </PlayButtonOverlay>
          <video ref={videoPlayerRef} src={src} />
        </>
      ) : (
        <LoadingGradient />
      )}
    </VideoContainer>
  );
}
