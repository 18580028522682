import BunchMessageComponent from "./components/BunchMessageComponent";
import Image from "next/image";
import Link from "next/link";
import LinkPreview from "../../../components/shared/LinkPreview";
import ModeratedMessageContent from "./ModeratedMessageContent";
import Username from "../../shared/user/Username";
import VideoWithFallback from "./components/VideoWithFallback";
import componentify from "../../../utilities/componentify";
import getUrl from "../../../utilities/getUrl";
import styled from "styled-components";
import { MEDIA_QUERY_MD } from "../../../utilities/constants/mediaQueries";
import { Portal } from "../../shared/Portal";
import { MessageDetailsFragment, UserDetailsFragment } from "../../../gql/types";
import { useState } from "react";
import PollMessageContent from "./PollMessageContent";
import RankingMessageContent from "./ranking/RankingMessageContent";

const Container = styled.div`
  position: relative;
`;

const TextContainer = styled.div<{ displayMode: boolean; isInline: boolean }>`
  max-width: 770px;
  width: 100%;
  word-break: break-word;
  white-space: pre-line;
  line-height: 1.4;
  font-size: 0.875rem;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    font-size: 1rem;
  }

  ${({ isInline }) =>
    isInline &&
    `
    color: var(--color-gray-3);
    font-size: 0.75rem;
    font-style: italic;
  `}

  & span {
    cursor: text;
  }

  ${({ displayMode }) =>
    displayMode &&
    `
      & span {
        cursor: pointer !important;
      }
  `}
`;

const Button = styled.button`
  position: relative;
  aspect-ratio: 1 / 1;
  width: 100%;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    width: 24rem;
  }
`;

const MediaImage = styled(Image)`
  border-radius: 0.75rem;
  object-fit: cover;
`;

const StyledLink = styled(Link)`
  color: var(--color-primary);
  display: inline;
`;

const ImagePreview = styled.div`
  position: fixed;
  z-index: var(--z-chat);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow:
    0 8px 25px -5px rgba(0, 0, 0, 0.25),
    0 16px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 0.5rem;
  padding: 1rem;
  max-height: 90vh;
  max-width: 80%;
  background: var(--color-background);
  border: 1px solid var(--color-gray-6);
  overflow: hidden;

  & img {
    max-height: calc(90vh - 2rem);
    width: auto;
  }
`;

export default function MessageContent({
  message,
  inline,
  isModerated,
  displayOnly = false,
  displayMode = false,
}: {
  message: MessageDetailsFragment;
  inline: boolean;
  isModerated: boolean;
  displayOnly?: boolean;
}) {
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  if (isModerated) return <ModeratedMessageContent />;
  const messageComponents = componentify(message.content);

  return (
    <>
      {imagePreview !== null && (
        <Portal onOutsideClick={() => setImagePreview(null)}>
          <ImagePreview>
            <Image src={imagePreview} alt="" width="800" height="800" />
          </ImagePreview>
        </Portal>
      )}
      <Container>
        <TextContainer displayMode={displayMode} isInline={inline}>
          {messageComponents &&
            messageComponents.length > 0 &&
            messageComponents.map((component, index) => {
              switch (component.type) {
                case "text":
                  return <span key={`MessageSegment-text-${index}`}>{component.content}</span>;
                case "url":
                  return (
                    <StyledLink
                      href={getUrl(component.content, false)}
                      key={`MessageSegment-url-${index}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {component.content}
                    </StyledLink>
                  );
                case "bunch_mention":
                  return (
                    <BunchMessageComponent
                      component={component}
                      key={`MessageSegment-bunchmention-${index}`}
                      index={index}
                    />
                  );
                case "user_mention":
                  return (
                    <Username
                      key={`MessageSegment-usermention-${index}`}
                      at={false}
                      user={{ userId: component.id || "", username: component.content } as UserDetailsFragment}
                    />
                  );
                default:
                  return <span key={`MessageSegment-default-${index}`}>{component.content}</span>;
              }
            })}
        </TextContainer>
        {message.content.media &&
          message.content.media.length > 0 &&
          message.content.media.map((media: any, index: number) => {
            switch (media.type) {
              case "image":
                return (
                  <Button
                    id="media-image-container"
                    key={`MediaImage-button-${index}`}
                    onClick={() => setImagePreview(media.url)}
                  >
                    <MediaImage src={media.url} alt="Media" fill />
                  </Button>
                );
              case "video":
                return <VideoWithFallback src={media.url} key={`MediaVideo-button-${index}`} />;
              default:
                return <div key={index}></div>;
            }
          })}
        {message.content.urls &&
          message.content.urls.length > 0 &&
          message.content.urls.map((url: any, index: number) => (
            <LinkPreview key={`LinkPreview-${index}`} url={url.content} />
          ))}

        {message.poll && <PollMessageContent message={message} displayOnly={displayOnly} />}
        {message.ranking && <RankingMessageContent message={message} displayOnly={displayOnly} />}
      </Container>
    </>
  );
}
