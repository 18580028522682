import { createPortal } from "react-dom";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useRef, useEffect, useState, ReactNode } from "react";
import styled from "styled-components";

type PortalProps = {
  children: ReactNode;
  onOutsideClick?: () => void;
};

const Container = styled.div`
  position: relative;
  z-index: var(--z-layer-modal);
`;

export const Portal = (props: PortalProps) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);
  const outsideClick = useDetectClickOutside({ onTriggered: () => props.onOutsideClick?.() });

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>("#portal");
    setMounted(true);
  }, []);

  return mounted && ref.current
    ? createPortal(<Container ref={outsideClick}>{props.children}</Container>, ref.current)
    : null;
};
