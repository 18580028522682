import { gql, TypedDocumentNode } from "@apollo/client";
import { GradeRankingMutation, GradeRankingMutationVariables } from "../types";

export const GRADE_RANKING: TypedDocumentNode<GradeRankingMutation, GradeRankingMutationVariables> = gql`
  mutation gradeRanking($clientRankingId: ID, $grade: String, $rankingId: ID) {
    gradeRanking(clientRankingId: $clientRankingId, grade: $grade, rankingId: $rankingId) {
      messageId
    }
  }
`;
