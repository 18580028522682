import { useMutation } from "@apollo/client";
import Image from "next/image";
import { MouseEvent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { CAST_POLL_VOTE } from "../../../gql/polls/castPollVote";
// import { END_POLL } from "../../../gql/polls/endPoll";
import { MessageDetailsFragment } from "../../../gql/types";
import { handleCaughtError } from "../../../utilities";
import Text from "../../shared/Text";
import { POLL_DETAILS, useRightSidebarContext } from "../../state/RightSidebar";
import PollOption from "./PollOption";

type PollMessageContentProps = {
  message: MessageDetailsFragment;
  displayOnly: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.75rem;
  background: var(--color-gray-7);
  max-width: 400px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleWithIcon = styled.div`
  display: flex;
  gap: 0.25rem;

  p {
    color: var(--color-gray-4);
    font-family: var(--font-family-headline);
    font-size: 0.75rem;
  }
`;

const ViewVotesButton = styled.button`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  font-family: var(--font-family-headline);
  font-size: 0.75rem;
`;

const MediaItemContainer = styled.div`
  position: relative;
  aspect-ratio: 2 / 1;
  border-radius: 0.75rem;
  overflow: hidden;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export default function PollMessageContent({ message, displayOnly = false }: PollMessageContentProps) {
  const { displayPanel } = useRightSidebarContext();
  const [disabled, setDisabled] = useState(
    message.poll.status === "closed" || message.poll.options.some((option) => option.isSelected),
  );
  const [winningOption, setWinningOption] = useState<string | null>(null);
  const [showResults, setShowResults] = useState(disabled);
  const [castPollVoteMut] = useMutation(CAST_POLL_VOTE);
  // const [endPollMut] = useMutation(END_POLL);

  const castPollVote = useCallback(
    async (pollOptionId: string) => {
      setDisabled(true);
      try {
        const { data } = await castPollVoteMut({
          variables: { pollOptionId },
        });
        if (data) setShowResults(true);
      } catch (err) {
        handleCaughtError(err);
      }
    },
    [castPollVoteMut],
  );

  // const endPoll = useCallback(async () => {
  //   if (!message.poll.pollId) return;
  //   try {
  //     await endPollMut({
  //       variables: {
  //         pollId: message.poll.pollId,
  //       },
  //     });
  //   } catch (err) {
  //     handleCaughtError(err);
  //   }
  // }, [message, endPollMut]);

  useEffect(() => {
    setDisabled(message.poll.status === "closed" || message.poll.options.some((option) => option.isSelected));
  }, [message.poll]);

  //Find winning poll options
  useEffect(() => {
    setWinningOption([...message.poll.options].sort((a, b) => b.progress - a.progress)[0].pollOptionId);
  }, [message.poll.options]);

  return (
    <>
      <Container
        onClick={(e: MouseEvent<HTMLElement>) => {
          if (displayOnly) return;
          e.stopPropagation();
          displayPanel(POLL_DETAILS, message.poll);
        }}
      >
        <Header>
          <TitleWithIcon>
            <Image src="/icons/poll-light.png" width="16" height="16" alt="" />
            <Text>POLL</Text>
          </TitleWithIcon>
          {!displayOnly && (
            <ViewVotesButton>
              VIEW VOTES
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 17 17" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.2922 7.79607L11.2111 6.77607C11.0389 6.60229 11.0389 6.3214 11.2111 6.14762C11.3837 5.97384 11.6631 5.97384 11.8353 6.14762L13.5133 7.76851C13.6379 7.89473 13.7068 8.0614 13.7068 8.24007C13.7068 8.41829 13.6379 8.58584 13.5133 8.71118L11.8353 10.3321C11.6631 10.5058 11.3837 10.5058 11.2111 10.3321C11.0389 10.1587 11.0389 9.8774 11.2111 9.70362L12.2913 8.68495L3.03163 8.71118C2.791 8.71118 2.5957 8.51207 2.5957 8.26673C2.5957 8.0214 2.791 7.82229 3.03163 7.82229L12.2922 7.79607Z"
                  fill="var(--color-primary)"
                />
              </svg>
            </ViewVotesButton>
          )}
        </Header>
        {!!message.poll.mediaItem && (
          <MediaItemContainer>
            <Image src={message.poll.mediaItem.url} alt="" fill style={{ objectFit: "cover" }} />
          </MediaItemContainer>
        )}
        <Text fontSize="1.125rem" bold>
          {message.poll.title}
        </Text>
        <OptionsContainer>
          {message.poll.options &&
            message.poll.options.map((option, i) => (
              <PollOption
                key={option.title + i}
                option={option}
                disabled={disabled || displayOnly}
                showResults={showResults}
                showCheckbox={message.poll.status === "open"}
                isWinning={option.pollOptionId === winningOption}
                castPollVote={castPollVote}
              />
            ))}
        </OptionsContainer>
      </Container>
    </>
  );
}
