import MessageActions from "./MessageActions";
import MessageContent from "./MessageContent";
import ReactionList from "./ReactionList";
import Subtext from "../../shared/Subtext";
import ThreadPreview from "./ThreadPreview";
import styled from "styled-components";
import { MessageDetailsFragment, UserDetailsFragment } from "../../../gql/types";
import { calcTimestamp } from "../../../utilities";

const StyledActions = styled(MessageActions)`
  display: none;
`;

const NoWrapSubText = styled(Subtext)`
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

const MessageContainer = styled.div<{ isInline: boolean }>`
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  border-radius: 0.75rem;
  gap: 1rem;
  padding: 0.5rem;
  padding-left: 64px;

  ${({ isInline }) =>
    isInline &&
    `
    color: var(--color-gray-3);
    font-size: 0.75rem;
    font-style: italic;
  `}

  @media (hover: hover) {
    &:hover {
      background: var(--color-gray-7);
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    }

    &:hover ${StyledActions} {
      display: flex;
    }
  }

  img {
    height: fit-content;
  }
`;

const MessageInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.125rem;
`;

const MessageHeaderDetailsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const MessageHeaderDetailsContent = styled.div`
  display: flex;
  gap: 0.5rem;
`;

function ConsolidatedMessage({
  message,
  creatorId,
  displayMode,
  bunchMembers,
  thread,
  isThread,
  onChooseThread,
  isHovering,
  replyCount,
}: {
  message: MessageDetailsFragment;
  creatorId?: string;
  displayMode: boolean;
  bunchMembers: UserDetailsFragment[];
  thread: MessageDetailsFragment[];
  isThread: boolean;
  onChooseThread: () => void;
  isHovering: boolean;
  replyCount: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}) {
  return (
    <MessageContainer isInline={message.type === "inline"}>
      <MessageInnerContainer>
        {message.isPinned && (
          <MessageHeaderDetailsContainer>
            <MessageHeaderDetailsContent>
              <NoWrapSubText>{calcTimestamp(new Date(), message.insertedAt)}</NoWrapSubText>
            </MessageHeaderDetailsContent>
          </MessageHeaderDetailsContainer>
        )}
        <StyledActions message={message} creatorId={creatorId} />
        <MessageContent message={message} inline={message.type === "inline"} isModerated={message.isModerated} />
        {message.reactions && message.reactions.length > 0 && (
          <ReactionList
            bunchMembers={bunchMembers}
            displayMode={displayMode}
            message={message}
            reactions={message.reactions}
          />
        )}
        {thread?.length > 0 && !isThread && message.type !== "inline" && (
          <ThreadPreview thread={thread} isHovering={isHovering} onClick={onChooseThread} replyCount={replyCount} />
        )}
      </MessageInnerContainer>
    </MessageContainer>
  );
}

export default ConsolidatedMessage;
