import styled from "styled-components";

const TextContainer = styled.div`
  color: var(--color-gray-4);
  font-size: 0.75rem;
  font-style: italic;
`;
export default function ModeratedMessageContent() {
  return <TextContainer>This message was muted for being off topic.</TextContainer>;
}
