import ConsolidatedMessage from "./ConsolidatedMessage";
import MessageActions from "./MessageActions";
import MessageContent from "./MessageContent";
import ReactionList from "./ReactionList";
import Skeleton from "react-loading-skeleton";
import Subtext from "../../shared/Subtext";
import ThreadPreview from "./ThreadPreview";
import UserAvatar from "./UserAvatar";
import Username from "./Username";
import styled from "styled-components";
import { MessageDetailsFragment } from "../../../gql/types";
import { THREAD, useRightSidebarContext } from "../../state/RightSidebar";
import { UserDetailsFragment } from "../../../gql/types";
import { calcTimestamp } from "../../../utilities";
import { useBunchesContext } from "../../state/BunchesProvider";
import { useEffect, useState } from "react";
import { useMessagesContext } from "../../state/MessagesProvider";
import MessageFooter from "./MessageFooter";
import GutterBar from "./GutterBar";
import TagItem from "../../shared/TagItem";

const UNSUPPORTED_MESSAGE_TYPES = [""];

const StyledActions = styled(MessageActions)`
  display: none;
`;

const NoWrapSubText = styled(Subtext)`
  white-space: nowrap;
  display: block;
  position: relative;
  bottom: 1px;
`;

const ComponentContainer = styled.div<{
  hasTagItem: boolean;
}>`
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 0.75rem;

  ${({ hasTagItem }) =>
    hasTagItem &&
    `
    padding: 0.25rem 0;
  `}
`;

const MessageComponentContainer = styled.div<{
  displayMode: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 1rem;
  padding: 0.5rem 0;

  @media (hover: hover) {
    ${({ displayMode }) =>
      !displayMode &&
      `
      &:hover {
        background: var(--color-gray-7);
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
      }
    `}

    &:hover ${StyledActions} {
      display: flex;
    }
  }
`;

const TagItemContainer = styled.div`
  align-self: flex-start;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
`;

const MessageContainer = styled.div<{
  hasTagItem: boolean;
  isInline: boolean;
  displayMode: boolean;
}>`
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  border-radius: 0.75rem;
  gap: 1rem;
  padding: 0 0.5rem;

  ${({ hasTagItem }) =>
    hasTagItem &&
    `
    margin-top: -0.5rem;
    padding: 0.25rem 0.5rem;
  `}

  ${({ isInline }) =>
    isInline &&
    `
    color: var(--color-gray-3);
    font-size: 0.75rem;
    font-style: italic;
    padding-left: 64px;
  `}

  ${({ displayMode }) =>
    displayMode &&
    `
    background: none;
    box-shadow: none;

    &: hover {
      background: none;
      box-shadow: none;
    }
  `}
`;

const MessageInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  img {
    height: fit-content;
  }
`;

const MessageInnerContent = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
`;

const MessageHeaderDetailsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-left: 0.5rem;
`;

const ThreadPreviewSkeleton = styled(Skeleton)`
  width: 120px;
  height: 40px;
  border-radius: 0.75rem;
  margin-top: 0.5rem;
`;

const UnsupportedText = styled(Subtext)`
  padding-left: 64px;
`;

type Props = {
  bunchMembers?: UserDetailsFragment[];
  creatorId?: string;
  currentDate?: Date;
  display?: boolean;
  isExternal?: boolean;
  isThread?: boolean;
  message: MessageDetailsFragment;
  onThreadsLoaded?: () => void;
  previousMessage?: MessageDetailsFragment;
};

function MessageComponent({
  bunchMembers = [],
  creatorId,
  currentDate = new Date(),
  display = false,
  isExternal = false,
  isThread = false,
  message,
  previousMessage,
}: Props) {
  const { replyCount } = message;

  const { displayPanel } = useRightSidebarContext();
  const { setActiveThreadId } = useBunchesContext();
  const { threads } = useMessagesContext();

  const isInline = message.type === "inline";
  const [displayMode] = useState(display || false);
  const [isHovering, setIshovering] = useState(false);
  const [thread, setThread] = useState<MessageDetailsFragment[]>([]);
  const timeSinceLastMessage = previousMessage
    ? new Date(message.insertedAt).valueOf() - new Date(previousMessage.insertedAt).valueOf()
    : 0;
  const isConsolidated =
    !message.tagItem &&
    !!previousMessage &&
    message.user.userId === previousMessage.user.userId &&
    timeSinceLastMessage < 300000;

  useEffect(() => {
    if (!message.messageId) return;

    if (message.messageId && threads[message.messageId]) {
      setThread(threads[message.messageId]);
    }
  }, [threads, message.messageId]);

  if (UNSUPPORTED_MESSAGE_TYPES.includes(message.type)) {
    if (isConsolidated) return null;

    return <UnsupportedText>Unsupported {message.type} message hidden.</UnsupportedText>;
  }

  if (isConsolidated && !isInline)
    return (
      <ConsolidatedMessage
        bunchMembers={bunchMembers}
        replyCount={replyCount}
        creatorId={creatorId}
        displayMode={displayMode}
        message={message}
        thread={thread}
        isThread={isThread}
        isHovering={isHovering}
        onChooseThread={() => {
          setActiveThreadId(message.messageId);
          displayPanel(THREAD);
        }}
        onMouseEnter={() => setIshovering(true)}
        onMouseLeave={() => setIshovering(false)}
      />
    );

  return (
    <ComponentContainer hasTagItem={!!message.tagItem}>
      {!!message.tagItem && <GutterBar tagItem={message.tagItem} />}
      <MessageComponentContainer displayMode={displayMode}>
        {!!message.tagItem && (
          <TagItemContainer>
            <TagItem tagItem={message.tagItem} />
          </TagItemContainer>
        )}
        <MessageContainer
          hasTagItem={!!message.tagItem}
          isInline={isInline}
          displayMode={displayMode}
          onMouseEnter={() => setIshovering(true)}
          onMouseLeave={() => setIshovering(false)}
        >
          {!isInline && <UserAvatar user={message.user} isReadOnly={displayMode} />}
          <MessageInnerContainer>
            <MessageInnerContent>
              {!isInline && (
                <Username isExternal={isExternal} isReadOnly={displayMode} user={message.user} showVerified />
              )}
              <MessageHeaderDetailsContainer>
                {!isInline && <NoWrapSubText>{calcTimestamp(currentDate, message.insertedAt)}</NoWrapSubText>}
              </MessageHeaderDetailsContainer>
              {!displayMode && <StyledActions message={message} creatorId={creatorId} />}
            </MessageInnerContent>
            <MessageContent displayMode={displayMode} message={message} inline={message.type === "inline"} isModerated={message.isModerated} />
            {message.reactions && message.reactions.length > 0 && (
              <ReactionList
                bunchMembers={bunchMembers}
                displayMode={displayMode}
                message={message}
                reactions={message.reactions}
              />
            )}
            {!thread.length && !isThread && !isExternal && replyCount > 0 && <ThreadPreviewSkeleton />}
            {thread.length > 0 && !isThread && !isInline && (
              <ThreadPreview
                replyCount={replyCount}
                thread={thread}
                isHovering={isHovering}
                displayMode={displayMode}
                onClick={() => {
                  setActiveThreadId(message.messageId);
                  displayPanel(THREAD);
                }}
              />
            )}
          </MessageInnerContainer>
        </MessageContainer>
        {message.footer && <MessageFooter isThread={isThread} message={message} />}
      </MessageComponentContainer>
    </ComponentContainer>
  );
}

export default MessageComponent;
