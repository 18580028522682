import Image from "next/image";
import styled from "styled-components";
import { MessageAction } from "../../../utilities/constants/messageActions";

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  cursor: pointer;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid transparent;
  user-select: none;

  &:hover {
    background: var(--color-background);
    border-color: var(--color-gray-5);
  }
`;

function MessageActionItem({
  action,
  actionHandler,
  isBusy = false,
}: {
  action: MessageAction;
  actionHandler: (action: MessageAction) => void;
  isBusy: boolean;
}) {
  return (
    <Button onClick={() => actionHandler(action)}>
      {isBusy && <span>...</span>}
      {!isBusy && <Image src={action.image} alt={action.label} title={action.label} width={16} height={16} />}
    </Button>
  );
}

export default MessageActionItem;
