import Image from "next/image";
import Username from "../../shared/user/Username";
import styled from "styled-components";
import { MessageDetailsFragment } from "../../../gql/types";
import { MessageReaction } from "../../../types";
import { useAuthContext } from "../../state/AuthProvider";
import { useEffect, useState } from "react";
import { useMessagesContext } from "../../state/MessagesProvider";

const GreetingImg = styled(Image)`
  flex: 0 1 auto;
`;

const Reactions = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ReactionUsers = styled.div`
  background: var(--color-gray-6);
  border-radius: 0.75rem;
  border: 1px solid var(--color-primary);
  bottom: calc(100% + 0.25rem);
  display: none;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.5rem;
  position: absolute;
  font-size: 0.75rem;
  z-index: var(--z-chat-actions);
`;

const ReactionContainer = styled.div`
  display: flex;
  gap: 1rem;

  @media (hover: hover) {
    &:hover ${ReactionUsers} {
      animation: fadeIn var(--animation-timing-quick) var(--timing-easeOutQuart);
      display: block;
    }
  }
`;

const ReactionItem = styled.div<{ didReact: boolean; isDisplayMode: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-gray-6);
  height: 2rem;
  width: 3.25rem;
  padding: 0 0.5rem;
  gap: 0.5rem;
  cursor: pointer;
  border-radius: 6.25rem;
  border: 1px solid transparent;
  transition: border-color var(--animation-timing-quick) var(--timing-easeOutQuart);

  ${({ didReact }) =>
    didReact &&
    `
    background: var(--color-gray-6);
    border-color: var(--color-primary);
  `}

  ${({ isDisplayMode }) =>
    !isDisplayMode &&
    `
    @media (hover: hover) {
      &:hover {
        background: var(--color-gray-6);
        border-color: var(--color-primary);
      }
    }
  `}

  ${({ isDisplayMode }) =>
    !!isDisplayMode &&
    `
    user-select: none;
  `}
`;

const ReactionItemCount = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
`;

const ReactionUsername = styled.span`
  font-size: 0.75rem;

  &:hover {
    color: var(--color-primary);
  }
`;

const ReactionArrow = styled.svg`
  position: absolute;
  color: var(--color-primary);
  width: 3rem;
  height: 0.5rem;
  top: 100%;
  left: 0;

  polygon {
    fill: currentColor;
  }
`;

function Reaction({
  reaction,
  displayMode,
  messageId,
  message,
  hideDidReact = false,
}: {
  reaction: MessageReaction;
  displayMode: boolean;
  messageId: string;
  message: MessageDetailsFragment;
  hideDidReact?: boolean;
}) {
  const { currentUser } = useAuthContext();
  const { addReaction, removeReaction } = useMessagesContext();
  const [didReact, setDidReact] = useState<boolean>(false);
  const [reactionUsers, setReactionUsers] = useState<any[]>([]);

  useEffect(() => {
    if (!currentUser?.userId) return;

    setDidReact(reaction.users.includes(currentUser.userId));
    setReactionUsers(reaction.reactors ?? []);
  }, [reaction.reactors, reaction.users, currentUser]);

  async function setShouldReact(shouldReact: boolean) {
    if (!messageId || displayMode || !currentUser) return;

    const reactVariables = {
      content: reaction.content,
      messageId: messageId,
      userId: currentUser.userId,
    };

    if (shouldReact) {
      const reactions: MessageReaction[] = (message?.reactions || []).map((r: MessageReaction) => {
        if (r.content === reaction.content) {
          return {
            ...r,
            users: [...r.users, currentUser?.userId],
            count: r.count + 1,
          };
        }

        return r;
      });

      const optimisticResponse = {
        ...message,
        reactions
      };

      await addReaction(reactVariables, optimisticResponse);
    } else if (!shouldReact) {
      const reactions: MessageReaction[] = (message?.reactions || []).map((r: MessageReaction) => {
        if (r.content === reaction.content) {
          return {
            ...r,
            users: r.users.filter((userId) => userId !== currentUser?.userId),
            count: r.count - 1,
          };
        }

        return r;
      });

      const optimisticResponse = {
        ...message,
        reactions
      };

      await removeReaction(reactVariables, optimisticResponse);
    }

    setDidReact(shouldReact);
  }

  function getReactionContent(reactionContent: string) {
    switch (reactionContent) {
      case "ga":
        return <GreetingImg width={16} height={16} alt="good afternoon." src="/icons/reactions/ga.png" />;
      case "gm":
        return <GreetingImg width={16} height={16} alt="good morning." src="/icons/reactions/gm.png" />;
      case "gn":
        return <GreetingImg width={16} height={16} alt="good night." src="/icons/reactions/gn.png" />;
      default:
        return <span>{reactionContent}</span>;
    }
  }

  if (reaction.count < 1) {
    return null;
  }

  return (
    <ReactionContainer>
      <Reactions onClick={() => setShouldReact(!didReact)}>
        <ReactionItem didReact={!hideDidReact && didReact} isDisplayMode={displayMode}>
          {getReactionContent(reaction.content)}
          <ReactionItemCount>{reaction.count}</ReactionItemCount>
        </ReactionItem>
      </Reactions>
      {!displayMode && (
        <ReactionUsers>
          {reactionUsers.length > 0 &&
            reactionUsers.map((user, index) => {
              return (
                <ReactionUsername key={`ReactionUsername-${index}`}>
                  {reactionUsers.length !== 1 && index === reactionUsers.length - 1 && "and "}
                  {user?.userId === currentUser?.userId ? "you" : <Username user={user} at={true} />}
                  {index !== reactionUsers.length - 1 && ","}{" "}
                </ReactionUsername>
              );
            })}
          reacted with {reaction.content}
          <ReactionArrow x="0px" y="0px" viewBox="0 0 255 255">
            <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
          </ReactionArrow>
        </ReactionUsers>
      )}
    </ReactionContainer>
  );
}

export default Reaction;
