import Image from "next/image";
import styled from "styled-components";
import { MessageDetailsFragment } from "../../../gql/types";
import { useChatContext } from "../../state/ChatProvider";

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--color-gray-6);
  width: 2rem;
  height: 2rem;
  cursor: pointer;
`;

const ReactionContainer = styled.div`
  position: relative;
`;

export default function AddReaction({ message, className }: { message: MessageDetailsFragment; className?: string }) {
  const { setReactingTo } = useChatContext();

  return (
    <ReactionContainer>
      <Button className={className} onClick={() => setReactingTo(message)}>
        <Image src="/icons/addReaction.png" alt="Add Reaction" width={16} height={16} />
      </Button>
    </ReactionContainer>
  );
}
